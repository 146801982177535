<template>
	<div
		class="custom-template-header"
		:class="{'page-header--hidden': isHeaderHidden}"
	>
		<SearchFormModal
			v-if="!isDesktop"
			id="custom-template-mobile-search-form"
			ref="searchFormModal"
			header="Suche anpassen"
			apply-button="Suchen"
			cancel-button="Abbrechen"
		/>
		<ThemeMinimal
			v-if="!isDesktop"
			class="custom-template-mobile-search-form__theme-minimal"
			:icon-name="'fa-solid fa-magnifying-glass'"
			@click="() => searchFormModal!.openModal()"
		/>
	</div>
</template>

<script lang="ts" setup>
import ThemeMinimal from '@lmt-rpb/ThemeMinimal/ThemeMinimal.vue';
import SearchModal from '@lmt-rpb/SearchModal/SearchModal.vue';
import {
 computed, onMounted, ref, onUnmounted,
} from 'vue';
import SearchFormModal from '@lmt-rpb/SearchFormModal/SearchFormModal.vue';
import { useStore } from '@/components/common/store';

const store = useStore();
const isDesktop = computed(() => store.state.config.isDesktop);
const searchFormModal = ref<InstanceType<typeof SearchModal>>();
const isHeaderHidden = ref(false);
const observer = ref<MutationObserver | null>(null);

const checkHeaderVisibility = () => {
	isHeaderHidden.value = document.body.classList.contains('page-header--hidden');
};

onMounted(() => {
	const observer = new MutationObserver(checkHeaderVisibility);
	observer.observe(document.body, {
		attributes: true,
		attributeFilter: ['class']
	});
});

onUnmounted(() => {
	observer.value?.disconnect();
	observer.value = null;
});

defineExpose({
	searchFormModal,
});
</script>

<style lang="scss">
/* Remove box-shadow when custom-template-header exists */
:has(.custom-template-header) header.page-header {
  box-shadow: none !important;
}

/* Add box-shadow back for large screens when custom-template-header exists */
@media (min-width: $breakpoint-extralarge) {
  :has(.custom-template-header) header.page-header {
    box-shadow: 0 0.3rem 0.3rem rgb(51 51 51 / 20%) !important;
  }
}
</style>

<style lang="scss" scoped>
.custom-template-header {
	position: sticky;
	top: 6rem;
	z-index: 997;
	background: $color-white;
	width: 100%;
	transition: top 0.16s ease, transform 0.16s ease, box-shadow 0.16s ease;
	padding: 0 !important;
	box-shadow: 0.3rem 0.6rem 1.4rem $color-dark-gray-t2;

	@media (min-width: $breakpoint-small) {
		top: 8.8rem;
	}

	&.page-header--hidden {
		top: 0;
		transform: translateY(-100%);
	}
}

.custom-template-mobile-search-form__theme-minimal {
	position: relative;
	z-index: 996;
	margin: 0;
}
</style>
