import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["href", "title"]
const _hoisted_2 = { class: "rpb_hotelbox__image" }
const _hoisted_3 = ["alt", "data-srcset", "data-src"]
const _hoisted_4 = { class: "rpb_hotelbox__subline" }
const _hoisted_5 = { class: "rpb_hotelbox__region" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "rpb_hotelbox__rating"
}
const _hoisted_9 = ["title"]
const _hoisted_10 = {
  key: 0,
  class: "rpb_hotelbox__text"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 1,
  class: "rpb_hotelbox__text"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "rpb_hotelbox__footer" }
const _hoisted_16 = {
  key: 0,
  class: "rpb_hotelbox__specialoffer"
}
const _hoisted_17 = { class: "rpb_hotelbox__specialoffer--discount" }
const _hoisted_18 = { class: "rpb_hotelbox__specialoffer--oldprice" }
const _hoisted_19 = { class: "rpb_hotelbox__price" }

import {
	ratingText,
	convertDateHelper,
} from '@utils/utils';
import Price from '@lmt-rpb/Price/Price.vue';
import HotelStars from '@lmt-rpb/HotelStars/HotelStars.vue';
import BookmarkHotelButton from '@lmt-rpb/BookmarkHotelButton/BookmarkHotelButton.vue';
import { computed } from 'vue';
import PromotionAnalytics from '@/js/modules/analytics/PromotionAnalytics';
import { ItemType, OfferType } from '@/interfaces/hotel-list-types/hotel-list-types';
import { extractTravelTypeFromOffer } from '@/js/utils/offerUtils';
import { Board } from '@/js/data/board-types';
import { HotelTransformService } from '@/components/HotelGrid/hotelTransformService';
import { useHistoricalPriceData } from '../../composables/discount';

const MIN_RATING_FOR_DISPLAY = 4.2

interface Props {
	deal?: boolean,
	item: ItemType,
	currencySymbol: string,
	hotelgrid?: boolean,
	showSuns: boolean,
	domain?: string,
	slider: boolean,
	promotionName: string,
	bestRating?: number,
	index: number,
	hasPromotionAnalytics?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HotelboxNew',
  props: {
    deal: { type: Boolean, default: false },
    item: {},
    currencySymbol: {},
    hotelgrid: { type: Boolean, default: false },
    showSuns: { type: Boolean, default: false },
    domain: { default: 'AT' },
    slider: { type: Boolean, default: true },
    promotionName: {},
    bestRating: { default: 6 },
    index: {},
    hasPromotionAnalytics: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props;

const hotel = computed(() => props.item.Hotel);
const region = computed(() => props.item.Location.Region);
const country = computed(() => props.item.Location.Country);
const city = computed(() => props.item.Location.City);

const offer = computed(() => extractTravelTypeFromOffer(props.item) as OfferType);

const hasOutboundFlight = computed(() => !!offer.value.Flight?.OutboundFlight);
const departureAirportTown = computed(() => offer.value.Flight?.OutboundFlight.AirportName);

const board = computed(() => Board[offer.value.Board]);

const wasPrice = computed(() => props.item.WasPrice);

const dealFactor = computed(() => props.item.WasPrice?.relativeDiscountPercent?.toAvgPrice ?? 0);
const {
	averagePriceFormatted,
	discount,
	hasDiscount,
	priceAverage
} = useHistoricalPriceData(wasPrice, props.currencySymbol);

const showDiscount = computed(() => dealFactor.value && dealFactor.value < 100 && hasDiscount.value && priceAverage.value);

const travelDuration = computed(() => offer.value.BetweenDeparturesDuration ?? offer.value.OvernightStays)

const images = computed(() => HotelTransformService.generateImageUrls(hotel.value.IffCode.toString()));

const containsBodyClass = (bodyClass: string): boolean => document.body.classList.contains(bodyClass);

const isRegionOrCity = computed((): boolean => containsBodyClass('page_region') || containsBodyClass('page_city') || containsBodyClass('page_topic_topregion'));

const boxClass = computed(() => ({
	'rpb_hotelbox--deal-list': props.deal && !props.slider,
	'rpb_hotelbox--slider': props.slider,
}));

const fireSelectPromotionEvent = (event: MouseEvent): void => {
	if (!props.hasPromotionAnalytics) {
		return;
	}
	const buttonType = event?.button;

	// Firing GA4 event for left and middle mouse button (works on desktop and mobile)
	if (buttonType === 0 || buttonType === 1) {
		const creativeName = dealFactor.value ? 'Restplätze' : 'Topseller';
		const promotionAnalytics = PromotionAnalytics.fromItemType(creativeName, props.promotionName, props.item, props.currencySymbol, props.index);
		promotionAnalytics.fireSelectPromotion();
	}
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass(["rpb_hotelbox", boxClass.value]),
    href: hotel.value.URL,
    title: hotel.value.Name,
    onMousedown: _cache[1] || (_cache[1] = ($event: any) => (fireSelectPromotionEvent($event)))
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        alt: hotel.value.Name,
        "data-srcset": images.value.s + ' 330w, ' + images.value.m + ' 513w',
        "data-src": images.value.m,
        src: "data:image/gif;base64,R0lGODdhEgAMAIABAPD2/////ywAAAAAEgAMAAACDYSPqcvtD6OctNqLVQEAOw==",
        class: "rpb_hotelbox__image rpb_lazy"
      }, null, 8 /* PROPS */, _hoisted_3),
      _createVNode(BookmarkHotelButton, {
        item: _ctx.item,
        class: "rpb_hotelbox__bookmark",
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
      }, null, 8 /* PROPS */, ["item"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, [
        (isRegionOrCity.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(city.value.CityName), 1 /* TEXT */))
          : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(region.value.RegionName), 1 /* TEXT */))
      ]),
      (_ctx.item.Ratings.Overall >= MIN_RATING_FOR_DISPLAY)
        ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_unref(ratingText)(_ctx.item.Ratings.Overall)) + " – " + _toDisplayString(_ctx.item.Ratings.Overall) + "/" + _toDisplayString(_ctx.bestRating), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("h3", {
      class: "rpb_hotelbox__hotel-name",
      title: hotel.value.Name
    }, _toDisplayString(hotel.value.Name), 9 /* TEXT, PROPS */, _hoisted_9),
    _createVNode(HotelStars, {
      "show-suns": _ctx.showSuns,
      rating: hotel.value.Category,
      class: "hotelrating--height"
    }, null, 8 /* PROPS */, ["show-suns", "rating"]),
    ((!_ctx.deal || (_ctx.deal && props.hotelgrid)))
      ? (_openBlock(), _createElementBlock("ul", _hoisted_10, [
          _createElementVNode("li", null, _toDisplayString(travelDuration.value) + " Tage", 1 /* TEXT */),
          _createElementVNode("li", null, _toDisplayString(board.value), 1 /* TEXT */),
          (hasOutboundFlight.value)
            ? (_openBlock(), _createElementBlock("li", _hoisted_11, "Flug ab " + _toDisplayString(departureAirportTown.value), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ]))
      : (_ctx.deal)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_12, [
            _createElementVNode("li", null, _toDisplayString(country.value.CountryName), 1 /* TEXT */),
            _createElementVNode("li", null, _toDisplayString(board.value), 1 /* TEXT */),
            _createElementVNode("li", null, _toDisplayString(travelDuration.value) + " Tage", 1 /* TEXT */),
            (hasOutboundFlight.value)
              ? (_openBlock(), _createElementBlock("li", _hoisted_13, " Flug ab " + _toDisplayString(departureAirportTown.value), 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            (offer.value.StartDate && departureAirportTown.value)
              ? (_openBlock(), _createElementBlock("li", _hoisted_14, "Abflug: " + _toDisplayString(_unref(convertDateHelper)(offer.value.StartDate, _ctx.domain)), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_15, [
      (_ctx.deal)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            (showDiscount.value)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_17, _toDisplayString(_unref(discount)) + "%", 1 /* TEXT */),
                  _createElementVNode("del", _hoisted_18, _toDisplayString(_unref(averagePriceFormatted)), 1 /* TEXT */)
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_19, [
        _createVNode(Price, {
          price: offer.value.Price,
          currency: _ctx.currencySymbol
        }, null, 8 /* PROPS */, ["price", "currency"]),
        _cache[2] || (_cache[2] = _createTextVNode(" p.P. "))
      ])
    ])
  ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1))
}
}

})