<template>
	<section
		:id="slider ? 'rpb_horizontal-scroller-vue' : 'rpb_horizontal-grid'"
		class="rpb_container"
		:class="slider ? 'rpb_container--slider' : ''"
	>
		<div
			class="rpb_offergrid"
			:class="[{ 'rpb_offergrid--deals': deal }]"
		>
			<ComponentHeader
				:header-props="headerProps"
			/>
			<div
				class="rpb_offergrid__content"
				:class="[{'rpb_offergrid__content--slider': slider }, { 'rpb_offergrid--4columns': fourColumns }]"
			>
				<Hotelbox
					v-for="(item, index) in itemList"
					:key="item.Hotel.IffCode"
					:hotelgrid="hotelGrid"
					:item
					:show-suns="showSuns"
					:currency-symbol="currencySymbol"
					:slider="slider"
					:deal="deal"
					:has-promotion-analytics
					:promotion-name="headerProps.mobileTitle || headerProps.title"
					:index="index"
					@mousedown="hotelClick(index, item)"
				/>
				<a
					v-if="slider && moreLink"
					:href="moreLink"
					class="rpb_hotelbox rpb_hotelbox__placeholder"
					:title="headerProps.titleAttribute"
				>
					<span
						class="rpb_btn--secondary rpb_btn--slider"
						:title="headerProps.titleAttribute"
					>Alle Angebote</span>
				</a>
				<div
					v-if="slider"
					class="rpb_offergrid__content--slider-overlay"
				></div>
			</div>
		</div>

		<div
			v-if="showBottomCTA"
			class="rpb_offergrid__bottom-cta"
		>
			<RpbButton
				:rpb-button-class="'rpb_button rpb_button--azure'"
				:text="bottomCTAText"
				:link="moreLink"
				rel-attr="nofollow"
			/>
		</div>
	</section>
</template>

<script lang="ts" setup>
import Hotelbox from '@lmt-rpb/HotelboxNew/HotelboxNew.vue';
import RpbButton from '@lmt-rpb/RpbButton/RpbButton.vue';
import ComponentHeader from '@lmt-rpb/ComponentHeader/ComponentHeader.vue';
import type { HeaderProps } from '@lmt-rpb/ComponentHeader/componentHeaderTypes';
import { ItemType } from '@/interfaces/hotel-list-types/hotel-list-types';

interface Props {
	itemList: Array<ItemType>,
	currencySymbol?: string,
	showSuns?: boolean,
	hotelGrid?: boolean
	headerProps: HeaderProps,
	headline?: string,
	showBottomCTA?: boolean,
	bottomCTAText?: string,
	slider?: boolean,
	destinationName?: string,
	deal?: boolean,
	fourColumns?: boolean,
	moreLink?: string,
	hasPromotionAnalytics?: boolean,
}

withDefaults(defineProps<Props>(), {
	currencySymbol: 'EUR',
	showSuns: true,
	hotelGrid: false,
	headline: '',
	showBottomCTA: false,
	bottomCTAText: '',
	slider: true,
	destinationName: '',
	deal: false,
	fourColumns: false,
	moreLink: '',
	hasPromotionAnalytics: true,
});

const emit = defineEmits<{(e:'hotelBoxClick', payload: {index: number, hotelData: ItemType}): void}>();

function hotelClick(index: number, hotel: ItemType) {
	emit('hotelBoxClick', { index, hotelData: hotel });
}
</script>

<style lang="scss" scoped>
.rpb_container {
	margin-bottom: $vgrid-mobile-large;

	&--margin-top {
		margin-top: $vgrid-mobile-first-element-top-margin;
	}
}

.rpb_offergrid__bottom-cta {
	line-height: 0;
	text-align: center;
}

/* Mobile */
.rpb_offergrid {
	position: relative;

	&__region-deals {
		margin-top: 1.5rem;
	}
}

.rpb_container .rpb_offergrid {
	margin-bottom: 4rem;
}

.rpb_offergrid__content {
	margin-top: 1.2rem;
	padding: 0.5rem $padding-normal 0;
	text-align: center;
	display: grid;
	justify-items: center;
	justify-content: center;
	row-gap: $offer-grid-gap-vertical;

	.rpb_hotelbox {
		display: flex;
		flex-direction: column;
	}

	.rpb_textbox,
	.rpb_text-picbox,
	.rpb_hotelbox,
	.rpb_video {
		float: none;
		max-width: 40rem; /* Max width of images is 33rem */
		overflow: hidden;
		width: 100%;
	}

	.rpb_textbox {
		padding: 0 !important;
	}

	.rpb_textbox:hover {
		text-decoration: none;
	}

	a.rpb_video {
		padding: 0;
	}

	h4 {
		width: 100%;
		text-align: center;
		margin-bottom: 1.2rem;
	}

	&.rpb_offergrid--deals {
		h2 {
			width: 100%;
		}
	}
}

div.rpb_offergrid__content::after {
	content: none;
}

.rpb_offergrid__nav {
	text-align: center;
	clear: both;

	.rpb_btn--secondary {
		margin-top: 3.6rem;
		max-width: 100%;
		white-space: normal;
		line-height: 1.7;
		padding-top: 0.8rem;
		padding-bottom: 0.8rem;
		font-size: $font-medium-2;

		span {
			display: none;
		}
	}
}

.rpb_offergrid__content--slider-overlay {
	background:
		-webkit-gradient(
			linear,
			left top,
			right top,
			color-stop(0%, rgb(255 255 255 / 0%)),
			color-stop(100%, rgb(255 255 255 / 100%))
		);
	position: absolute;
	pointer-events: none;
	height: calc(100% - 2rem); // -2rem because of the scrollbar
	width: 4rem;
	right: -0.2rem;
	top: initial;
}

/* Mobile landscape */
@media (min-width: $breakpoint-small) {
	.rpb_box__text {
		flex: 1 0 auto;
	}

	.rpb_offergrid__content {
		grid-template-columns: repeat(auto-fill, 49%);
		gap: $offer-grid-gap-vertical $offer-grid-gap-horizontal;
		text-align: left;
		justify-content: space-between;

		.rpb_textbox,
		.rpb_text-picbox,
		.rpb_video {
			margin-top: 0;
			max-width: 100%;
			display: flex;
			flex-direction: column;
		}
	}
}

/* Tablet Portrait */
@media (min-width: $breakpoint-small) {
	.rpb_container {
		margin-bottom: $vgrid-desktop-large;

		&--margin-top {
			margin-top: $vgrid-mobile-first-element-top-margin;
		}
	}

	.rpb_offergrid > .rpb_offergrid__headlines > .rpb_offergrid__headline {
		font-size: 2.6rem;
	}

	.rpb_offergrid__content {
		.rpb_hotelbox--deal-list,
		.rpb_hotelbox {
			max-width: none;
			width: 100%;
		}
	}
}

/* Tablet landscape */
@media (min-width: $breakpoint-scroller) {
	.rpb_offergrid__content {
		grid-template-columns: repeat(auto-fill, 32%);
		font-size: 0;

		.rpb_textbox,
		.rpb_hotelbox {
			max-width: 46rem;
			width: 100%;
		}

		.rpb_text-picbox,
		.rpb_video {
			font-size: $font-small-2;
			max-width: none;
		}

		h4 {
			margin-bottom: 2rem;
		}
	}

	.rpb_offergrid--4columns.rpb_offergrid__content {
		grid-template-columns: repeat(auto-fill, 23.6%);
	}
}

@media (max-width: $breakpoint-mobilelarge) {
	.rpb_offergrid > .rpb_offergrid__headlines > .rpb_offergrid__headline {
		font-size: $font-small-1;
		padding-left: 0.5rem;
	}

	.rpb_offergrid > .rpb_offergrid__headlines > .rpb_offergrid__subheadline {
		font-size: $font-small-3;
	}
}

@media (max-width: $breakpoint-scroller) {
	.rpb_container--slider .rpb_offergrid {
		overflow: hidden;
	}

	.rpb_offergrid > .rpb_offergrid__headlines > h2 {
		text-align: left;
		font-size: $font-medium-3;
		font-weight: $font-weight-semibold;
		padding: 0 0 0 1.6rem;
		flex: 1 1 auto;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.rpb_offergrid .rpb_headline__h2 {
		display: none;
	}

	.rpb_offergrid__headlines {
		display: flex;
		flex-direction: row;
		align-items: baseline;

		.rpb_offergrid__subheadline {
			font-size: $font-small-2;
			font-weight: $font-weight-regular;
			color: #16828F;
			flex: 0 0 auto;
			padding: 0 1.1rem 0 0.5rem;
			margin: 0;
			white-space: nowrap;
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.rpb_container--slider .rpb_offergrid__bottom-cta {
		display: none;
	}

	.rpb_content .rpb_main-content .rpb_container--slider {
		padding-left: 0;
		padding-right: 0;
	}

	.rpb_offergrid__content--slider {
		@include customHorizontalScrollbar;

		font-size: $font-small-2;
		list-style: none;
		display: flex;
		flex-wrap: inherit;
		gap: 0;
		width: 100%;
		padding: 0.5rem $padding-normal 1rem;
		margin: 0;
		white-space: nowrap;
		overflow: scroll hidden;
		-webkit-overflow-scrolling: touch;
		justify-content: initial;

		.rpb_hotelbox {
			width: 31rem;
			float: none;
			padding-top: 0;
			padding-left: 0;
			padding-right: 0;
			margin: 0 0.5rem;
			flex: 1 0 auto;

			&__placeholder {
				background-color: $color-light-gray;
				vertical-align: bottom;
				display: inline-flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				box-shadow: none;
				padding: 0 4.8rem;
				border-radius: 0.5rem;

				&:hover {
					box-shadow: none;
				}

				&:active,
				&:focus {
					text-decoration: none;
				}

				.rpb_btn--slider {
					color: white;
					padding: 0 3rem;
					font-size: $font-medium-2;
					line-height: 1.7;
				}
			}
		}
	}

	.rpb_offergrid__nav--slider {
		display: none;
	}

	.rpb_offergrid--deals {
		.rpb_offergrid__content--slider {
			padding-left: $padding-normal;

			.rpb_hotelbox--specialoffer {
				height: auto;
				min-height: $grid-item-min-height;
				width: 31rem;
			}

			.rpb_hotelbox .rpb_btn--primary {
				padding: 0.4rem;
			}

			.rpb_hotelbox__placeholder {
				height: auto;
				width: 31rem;
			}
		}
	}
}

/* Scroller Breakpoint */
@media (min-width: $breakpoint-scroller + 1px) {
	.rpb_container--slider {
		.rpb_offergrid__bottom-cta {
			display: block;
		}
	}

	.rpb_offergrid__content--slider .rpb_hotelbox {
		&__placeholder {
			display: none;
		}
	}

	.rpb_offergrid__slider-headline {
		display: none;
	}

	.rpb_offergrid .rpb_headline__h2 {
		padding-top: 0;
	}

	.rpb_offergrid > .rpb_offergrid__headlines > .rpb_offergrid__headline {
		font-size: $font-large-2;
	}

	.rpb_offergrid__content--slider-overlay {
		display: none;
	}
}

/* Desktop HD */
@media (min-width: $breakpoint-verylarge) {
	.rpb_offergrid {
		&__content {
			padding: 0;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}
}
</style>
