import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

import ThemeMinimal from '@lmt-rpb/ThemeMinimal/ThemeMinimal.vue';
import SearchModal from '@lmt-rpb/SearchModal/SearchModal.vue';
import {
 computed, onMounted, ref, onUnmounted,
} from 'vue';
import SearchFormModal from '@lmt-rpb/SearchFormModal/SearchFormModal.vue';
import { useStore } from '@/components/common/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'MobileSearchHeader',
  setup(__props, { expose: __expose }) {

const store = useStore();
const isDesktop = computed(() => store.state.config.isDesktop);
const searchFormModal = ref<InstanceType<typeof SearchModal>>();
const isHeaderHidden = ref(false);
const observer = ref<MutationObserver | null>(null);

const checkHeaderVisibility = () => {
	isHeaderHidden.value = document.body.classList.contains('page-header--hidden');
};

onMounted(() => {
	const observer = new MutationObserver(checkHeaderVisibility);
	observer.observe(document.body, {
		attributes: true,
		attributeFilter: ['class']
	});
});

onUnmounted(() => {
	observer.value?.disconnect();
	observer.value = null;
});

__expose({
	searchFormModal,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["custom-template-header", {'page-header--hidden': isHeaderHidden.value}])
  }, [
    (!isDesktop.value)
      ? (_openBlock(), _createBlock(SearchFormModal, {
          key: 0,
          id: "custom-template-mobile-search-form",
          ref_key: "searchFormModal",
          ref: searchFormModal,
          header: "Suche anpassen",
          "apply-button": "Suchen",
          "cancel-button": "Abbrechen"
        }, null, 512 /* NEED_PATCH */))
      : _createCommentVNode("v-if", true),
    (!isDesktop.value)
      ? (_openBlock(), _createBlock(ThemeMinimal, {
          key: 1,
          class: "custom-template-mobile-search-form__theme-minimal",
          "icon-name": 'fa-solid fa-magnifying-glass',
          onClick: _cache[0] || (_cache[0] = () => searchFormModal.value!.openModal())
        }))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}
}

})