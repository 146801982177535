import { getFluxImageUrl } from '@utils/utils';
import { getBaseUrl } from '@utils/environmentUtils';
import type { ApiDataTypes } from '@components/common/types';
import { mapper } from '@services/transform';
import boardTypes from '@/js/data/board-types';
import type { ItemType } from '@/interfaces/hotel-list-types/hotel-list-types';

enum DestinationType {
	Country = 'country',
	Region = 'region',
	City = 'city'
}

export class HotelTransformService {
	private static readonly mainBaseUrl: string = getBaseUrl();

	private static readonly imageBaseUrl: string = getFluxImageUrl() || 'https://images.flux.reisen';

	public static generateImageUrls(iffCode: string) {
		return {
			l: `${this.imageBaseUrl}/hotel/${iffCode}/1/l`,
			m: `${this.imageBaseUrl}/hotel/${iffCode}/1/m`,
			s: `${this.imageBaseUrl}/hotel/${iffCode}/1/s`,
			xs: `${this.imageBaseUrl}/hotel/${iffCode}/1/xs`
		};
	}

	public static buildLinkParams(apiParams: ApiDataTypes | false, hotelOnly = false): string {
		if (!apiParams) return '';

		const params: Record<string, any> = {
			ddate: apiParams.RelativeStartDate || apiParams.StartDate,
			rdate: apiParams.RelativeEndDate || apiParams.EndDate,
			adult: apiParams.Adults,
			dur: apiParams.MinDuration !== apiParams.MaxDuration
				? `${apiParams.MinDuration},${apiParams.MaxDuration}`
				: apiParams.MaxDuration,
			depap: apiParams.DepartureAirport,
			...(apiParams.DirectFlight ? { dfl: 1 } : {}),
			...(apiParams.TourOperatorCodes?.length ? { brand: apiParams.TourOperatorCodes?.join(',') } : {}),
			...(apiParams.BoardTypes?.length ? { board: mapper(apiParams.BoardTypes, boardTypes, (obj) => obj.showInMenu) } : {}),
			...(apiParams.Children?.length ? { child: apiParams.Children?.join('-') } : {})
		};

		const paramsArray = Object.entries(params);
		let paramsString = '?';

		paramsArray.forEach((keyValue) => {
			paramsString += `${keyValue.join('=')}&`;
		});

		paramsString += hotelOnly ? 'ibe=hotel' : 'ibe=package';
		return paramsString;
	}

	public static buildListUrl(apiParams: ApiDataTypes | false, id: number, destinationType: DestinationType, hotelOnly = false): string {
		const destinationTypes = {
			[DestinationType.Country]: `/hotels/g/${id}/`,
			[DestinationType.Region]: `/hotels/r/${id}/`,
			[DestinationType.City]: `/hotels/o/${id}/`
		};

		const linkParams = this.buildLinkParams(apiParams, hotelOnly);

		return destinationTypes[destinationType] + linkParams;
	}

	public static transformUrl(
		offer: ItemType,
		apiParams: ApiDataTypes | false,
		hotelOnly = false,
		fromPlugin = true
	) {
		let url = `${this.mainBaseUrl}${offer.Hotel.URL}`;
		if (fromPlugin) {
			url += this.buildLinkParams(apiParams, hotelOnly) || '';
		}
		return url
	}
}
