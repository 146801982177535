import { createApp } from 'vue';
import { mountReplace } from '@/js/utils/vue-mount-replacer';
import MobileSearchHeader from './MobileSearchHeader.vue';

const mountPoint = document.querySelector('#custom-template-mobile-search');

// it will be add just in case the search form is available and mounting point exists
function hasHeaderBildElement(): boolean {
	return !!document.querySelector('.search-form__search');
}

function mountMobileSearchHeader() {
	if (mountPoint && hasHeaderBildElement()) {
		const app = createApp(MobileSearchHeader);
		mountReplace(app, mountPoint);
	} else {
		mountPoint?.remove();
	}
}

// trigger later because the serach-form is not available earlier
document.addEventListener('DOMContentLoaded', () => {
	mountMobileSearchHeader();
});
